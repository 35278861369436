import { Event, ViewerContext } from '@deal/web-tracking'

export interface KeywordSearchEventProperties {
  query: string
  typeahead_suggestion_selected: string | null
  typeahead_suggestions: string[]
  position: number | null
  consumer_search_id: string | null
}

export class KeywordSearchEvent extends Event<KeywordSearchEventProperties> {
  static displayName = 'Consumer Keyword Search'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
