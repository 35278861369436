import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ArticleEntryPointClickedEventProperties {
  article_id: string
  entry_point: ConsumerTracking.ArticleEntryPoint
}

export class ArticleEntryPointClickedEvent extends Event<ArticleEntryPointClickedEventProperties> {
  static displayName = 'Article Entry Point Clicked'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
