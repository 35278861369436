// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellableKeywordTypeaheadQueryVariables = Types.Exact<{
  input: Types.SellableKeywordTypeaheadInput;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type SellableKeywordTypeaheadQuery = { __typename: 'Query', sellableKeywordTypeahead: { __typename: 'SellableKeywordTypeaheadResult', typeaheadResults: Array<string>, trackingId?: any | null } };


export const SellableKeywordTypeaheadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SellableKeywordTypeahead"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SellableKeywordTypeaheadInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sellableKeywordTypeahead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"typeaheadResults"}},{"kind":"Field","name":{"kind":"Name","value":"trackingId"}}]}}]}}]} as unknown as DocumentNode;
export type SellableKeywordTypeaheadComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables>, 'query'> & ({ variables: SellableKeywordTypeaheadQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SellableKeywordTypeaheadComponent = (props: SellableKeywordTypeaheadComponentProps) => (
      <ApolloReactComponents.Query<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables> query={SellableKeywordTypeaheadDocument} {...props} />
    );
    

/**
 * __useSellableKeywordTypeaheadQuery__
 *
 * To run a query within a React component, call `useSellableKeywordTypeaheadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellableKeywordTypeaheadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellableKeywordTypeaheadQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSellableKeywordTypeaheadQuery(baseOptions: Apollo.QueryHookOptions<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables>(SellableKeywordTypeaheadDocument, options);
      }
export function useSellableKeywordTypeaheadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables>(SellableKeywordTypeaheadDocument, options);
        }
export function useSellableKeywordTypeaheadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables>(SellableKeywordTypeaheadDocument, options);
        }
export type SellableKeywordTypeaheadQueryHookResult = ReturnType<typeof useSellableKeywordTypeaheadQuery>;
export type SellableKeywordTypeaheadLazyQueryHookResult = ReturnType<typeof useSellableKeywordTypeaheadLazyQuery>;
export type SellableKeywordTypeaheadSuspenseQueryHookResult = ReturnType<typeof useSellableKeywordTypeaheadSuspenseQuery>;
export type SellableKeywordTypeaheadQueryResult = Apollo.QueryResult<SellableKeywordTypeaheadQuery, SellableKeywordTypeaheadQueryVariables>;