import { LocationDescriptor } from 'history'
import { ConsumerTracking } from '@deal/web-tracking/types'
import { useIdentityContext } from '#src/app/containers/Identity'
import { PotentialSourcesForGenerateReferralUrlFragment } from './GenerateReferralUrl.generated'

interface GenerateReferralUrlProps {
  expertId?: string
  potentialSources?: PotentialSourcesForGenerateReferralUrlFragment[]
  source: ConsumerTracking.EntryPoint
}

export default function useReferralUrl({
  potentialSources = [],
  source,
  expertId
}: GenerateReferralUrlProps): LocationDescriptor | null {
  const { myself } = useIdentityContext()

  if (!myself?.user.isAllowedToRefer) {
    return null
  }

  const expertSpecificReferral = potentialSources.find(referral => {
    if (
      referral.source.__typename === 'ReferralSourceConsumerToExpert' &&
      referral.source.referredToExpert.id === expertId
    ) {
      return referral.code
    }
  })

  const params = new URLSearchParams({ source })

  if (expertId) {
    params.set('expertId', expertId)
  }

  if (expertSpecificReferral) {
    return {
      pathname: `/referral/${expertSpecificReferral.code}`,
      search: params.toString()
    }
  } else {
    /**
     * We couldn't find one associated with this expert, attempt to grab the user's default
     * code. If there isn't one (unlikely / impossible in this branch) then grab one of the
     * returned ones.
     */
    const coercedCode = myself.user.referralCode
      ? myself.user.referralCode
      : potentialSources[0].code

    return {
      pathname: `/referral/${coercedCode}`,
      search: params.toString()
    }
  }
}
