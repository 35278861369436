import { Event, ViewerContext } from '@deal/web-tracking'

export interface TopListEntryPointClickedEventProperties {
  top_list_id: string
  entry_point: string
}

export class TopListEntryPointClickedEvent extends Event<TopListEntryPointClickedEventProperties> {
  static displayName = 'Top List Entry Point Clicked'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
