import React, { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import classNames from 'classnames'
import { useHistory } from '@deal/router'
import { useKeyPress, useOnClickOutside } from '@deal/dom-hooks'
import { autosuggestHighlight } from '@deal/components'
import { buildSearchResultsPageUrl } from '#src/app/services/search/buildSearchResultsPageUrl'
import { KeywordSearchEvent } from '#src/app/events/KeywordSearchedEvent'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import PageContent from '#src/app/components/PageContent'
import { useSellableKeywordTypeaheadQuery } from './SellableKeywordTypeahead.generated'
import SearchIcon from './search.svg'
import ClearIcon from './clear.svg'
import styles from './styles.css'

interface SearchProps {
  isOpen: boolean
  onRequestClose: () => void
}

const Search: React.FC<React.PropsWithChildren<SearchProps>> = ({ isOpen, onRequestClose }) => {
  const analytics = useAnalyticsContext()
  const history = useHistory()

  const inputRef = useRef<HTMLInputElement>(null)

  const [inputKeywords, setInputKeywords] = useState('')

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus()
    }
  }, [isOpen])
  const debouncedInputKeywords = useDebounce(inputKeywords, 200)[0]

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    setInputKeywords(inputValue)
  }

  const { data: nextKeywordTypeaheadData, previousData: previousKeywordTypeaheadData } =
    useSellableKeywordTypeaheadQuery({
      variables: {
        input: {
          queryKeyword: debouncedInputKeywords
        },
        limit: 10
      },
      fetchPolicy: 'cache-and-network',
      skip: debouncedInputKeywords.length === 0
    })

  const keywordTypeaheadData = nextKeywordTypeaheadData || previousKeywordTypeaheadData

  const typeaheadResults = keywordTypeaheadData?.sellableKeywordTypeahead.typeaheadResults || []

  const hasResults = typeaheadResults.length > 0

  //Add some handling for escape press and clicking outside to dismiss search
  const escapeKeyPress = useKeyPress('Escape')
  useEffect(() => {
    if (escapeKeyPress && isOpen) {
      onRequestClose()
    }
  }, [escapeKeyPress, isOpen, onRequestClose])

  // if there are no results below the search input, clicking outside should close the search
  const panelRef = useOnClickOutside<HTMLDivElement>(() => {
    if (!hasResults && isOpen) {
      onRequestClose()
    }
  })

  /**
   * Handler for when the form is submitted and we navigate to the selected search entry
   */
  const commitSearch = (keywords: string | null = null, position: number | null = null) => {
    const serializedLocation = buildSearchResultsPageUrl({
      keywords: keywords || inputKeywords,
      consumerSearchId: keywordTypeaheadData?.sellableKeywordTypeahead.trackingId,
      entryPoint: 'nav-keywords'
    })

    // Reset the search state
    setInputKeywords('')

    onRequestClose()

    analytics?.track(
      new KeywordSearchEvent({
        query: inputKeywords,
        typeahead_suggestion_selected: keywords,
        typeahead_suggestions: typeaheadResults,
        consumer_search_id: keywordTypeaheadData?.sellableKeywordTypeahead.trackingId,
        position: position !== null ? position + 1 : null
      })
    )
    history.push(serializedLocation)
  }

  return (
    <>
      {isOpen && <ScrollLock />}

      <div
        className={classNames(styles.panel, {
          [styles.panelVisible]: isOpen
        })}
        role="region"
        aria-label="Search"
      >
        <div
          className={styles.top}
          data-testid="search-panel"
          onClick={e => e.stopPropagation()}
          ref={panelRef}
        >
          <PageContent>
            <div className={styles.topHeader}>
              <div className={styles.prompt}>What are you looking for?</div>
              <button
                className={styles.closeContainer}
                onClick={() => {
                  setInputKeywords('')
                  onRequestClose()
                }}
                data-testid="search-panel-close"
                aria-label="Cancel search"
              >
                <span className={styles.closeLabel}>Cancel</span>
                <ClearIcon className={styles.closeIcon} />
              </button>
            </div>
            <form
              action={''}
              className={styles.form}
              onSubmit={e => {
                e.preventDefault()

                commitSearch()
                inputRef.current?.blur()
              }}
            >
              <div className={styles.searchInputContainer}>
                <SearchIcon className={styles.searchIcon} data-testid="mobile-search-icon" />
                <input
                  title="Search"
                  ref={inputRef}
                  type="search"
                  name="q"
                  placeholder="Search in all departments..."
                  onFocus={event => {
                    event.target.setAttribute('autocomplete', 'off')
                  }}
                  value={inputKeywords}
                  onChange={e => onInputChange(e)}
                  className={styles.searchInput}
                  aria-label="Search in all departments"
                  data-testid="search-keyword-input"
                />
                {inputKeywords.length > 0 && (
                  <button
                    type="button"
                    className={styles.clear}
                    onClick={() => {
                      setInputKeywords('')
                    }}
                  >
                    <ClearIcon />
                  </button>
                )}
              </div>
            </form>
          </PageContent>
        </div>

        <div
          className={classNames(styles.bottomContainer, {
            [styles.hide]: !hasResults
          })}
        >
          <TouchScrollable>
            <ul className={styles.bottomList}>
              {hasResults &&
                typeaheadResults.map((result, index) => {
                  const matches = autosuggestHighlight.match(result, inputKeywords)
                  const parts = autosuggestHighlight.parse(result, matches)
                  return (
                    <li
                      key={result}
                      className={styles.bottomContainerRow}
                      onClick={() => {
                        commitSearch(result, index)
                      }}
                    >
                      <PageContent className={styles.bottomContainerRowContent}>
                        <span>
                          {parts.map(part => {
                            if (part.highlight) {
                              return <strong key={part.text}>{part.text}</strong>
                            }

                            return <React.Fragment key={part.text}>{part.text}</React.Fragment>
                          })}
                        </span>
                      </PageContent>
                    </li>
                  )
                })}
            </ul>
          </TouchScrollable>
        </div>
      </div>
    </>
  )
}

export default Search
