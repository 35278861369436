import { useCallback } from 'react'
import { useHistory } from '@deal/router'
import { useShowSearchBarAtom } from '../atoms/useShowSearchBarAtom'

export default function useShowSearchBar(): [boolean, (isSearchVisible: boolean) => void] {
  const history = useHistory()
  const [isSearchVisible, setIsSearchVisible] = useShowSearchBarAtom()

  const updateSearchVisibility = useCallback(
    (isSearchVisible: boolean) => {
      setIsSearchVisible(isSearchVisible)
      // update location state to interact with chat widget v1, this prevents scroll lock conflicts
      history.replace({
        ...history.location,
        state: {
          ...(history.location.state as any),
          isSearchExpanded: isSearchVisible
        }
      })
    },
    [history, setIsSearchVisible]
  )

  return [isSearchVisible, updateSearchVisibility]
}
