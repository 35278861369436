import React, { HTMLAttributes } from 'react'
import { ReleaseConsumerIdentityComponent } from './ReleaseConsumerIdentity.generated'

interface ReleaseConsumerIdentityLinkProps extends HTMLAttributes<HTMLAnchorElement> {}

const ReleaseConsumerIdentityLink: React.FC<React.PropsWithChildren<ReleaseConsumerIdentityLinkProps>> = ({
  onClick,
  ...rest
}) => {
  return (
    <ReleaseConsumerIdentityComponent>
      {releaseConsumerIdentity => (
        <a
          onClick={(...args) => {
            releaseConsumerIdentity().then(() => {
              /**
               * Reload the window entirely, this will suspend and re-instantiate all
               * the active subscriptions (cart, chat) that we have. We'll get a refetch
               * identity for free with the page reload.
               */
              window.location.reload()
            })

            if (onClick) {
              onClick(...args)
            }
          }}
          {...rest}
        />
      )}
    </ReleaseConsumerIdentityComponent>
  )
}

export default ReleaseConsumerIdentityLink
