import React from 'react'
import { GlobalBanner as BluxomeGlobalBanner, GlobalBannerCallout } from '@deal/bluxome'
import { doesDeptSupportFreeShipping } from '#src/app/services/categories'
import { useBlackFridayPromotion } from '#src/app/hooks/useBlackFridayPromotion'
import { useDepartment } from '#src/app/context/department'

const GlobalBanner: React.FC = () => {
  const department = useDepartment()
  const blackFridayPromotion = useBlackFridayPromotion()

  const callouts: GlobalBannerCallout[] = []

  // BFCM 2024
  if (blackFridayPromotion) {
    callouts.push({
      content: `Get ${blackFridayPromotion.rewardCreditPercent}% credit on select products. Code: ${blackFridayPromotion.code}`,
      link: blackFridayPromotion.ctaDestination
    })
  }

  // Free shipping
  const freeShipping = department && doesDeptSupportFreeShipping(department?.type)
  if (freeShipping) {
    callouts.push({
      content: 'Free shipping on orders over $50'
    })
  }
  // Free personalized recommendations
  callouts.push({
    content: 'Get personalized recommendations',
    link: '/how-it-works'
  })

  return <BluxomeGlobalBanner callouts={callouts} />
}

export default GlobalBanner
